import React from 'react'

import * as queries from '../graphql/queries'
import { API, DataStore, Predicates } from 'aws-amplify'
import PostPreview from './PostPreview'
import { LoaderContext } from '../Context';
import { SubHeader } from './Text';
import { HiChevronDoubleDown } from 'react-icons/hi';
import { Post } from '../models';


export default function PostsList({ channel, user, limit, showPostedBy=true, showChannelBadge=true }) {
    let [posts, setPosts] = React.useState([])
    let [nextToken, setNextToken] = React.useState(undefined)
    const { isLoading, setLoading } = React.useContext(LoaderContext)

    function getPosts() {
        setLoading(true)
        const POSTS_IN_CHANNEL_QUERY = "postsInChannel"
        const POSTS_BY_USER_QUERY = "postsByUser"
        const ALL_POSTS_QUERY = "postByDate"
        
        let query = ALL_POSTS_QUERY

        let variables = {
            type:"public",
            limit: limit ? limit : 4,
            nextToken: nextToken,
            sortDirection: "DESC",
        }

        if (channel) {
            query = POSTS_IN_CHANNEL_QUERY
            variables.channel = channel
        }

        if (user) {
            query = POSTS_BY_USER_QUERY
            variables.user = user
        }

        API.graphql({
            query: queries[query],
            variables
        }).then(({ data }) => {
            const _posts = data[query].items
            const _nextToken = data[query].nextToken
            setPosts([...posts, ..._posts])
            setNextToken(_nextToken)
            setLoading(false)
        }).catch((error) => {
            console.error("error querying posts:", error)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        setPosts([])
        getPosts()
    }, [])

    function loadNext() {
        if (nextToken) {
            getPosts()
        }
    }

    if (posts.length == 0 && isLoading === false) {
        return (<div> No posts {channel ? `yet in ${channel}` : ""}</div>)
    }

    return (
        <div>
            {posts.map(p => <PostPreview post={p} key={p.id} showChannelBadge={showChannelBadge} showPostedBy={showPostedBy} />)}

            {
                nextToken ? (
                    <div className='flex flex-row justify-center'>
                        <HiChevronDoubleDown className='cursor-pointer h-6 w-6 m-2 text-center' onClick={loadNext} />
                    </div>
                ) : ""
            }
        </div>
    )
}

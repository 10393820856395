import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { API } from 'aws-amplify'
import { Button, ButtonGroup, SelectField, TextAreaField, TextField, useAuthenticator } from '@aws-amplify/ui-react'
import * as mutations from '../graphql/mutations'

import { channels } from '../config'
import NotificationContext from '../Notifications'

import { v4 } from 'uuid'
import { FaBold } from 'react-icons/fa'
import { TbH1, TbH2, TbBold, TbLink } from 'react-icons/tb'

export default function PostCreate() {
    const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus])
    const [loading, setLoading] = React.useState(false)
    const [post, setPost] = React.useState({ title: undefined, channel: undefined, content: undefined })
    const [errors, setErrors] = React.useState({ title: false, channel: false, content: false })
    const { setNotification } = React.useContext(NotificationContext)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        const channelParam = searchParams.get("channel")
        if (channelParam) {
            setPost({ ...post, channel: channelParam })
        }
    }, [searchParams])

    function validatePost() {
        const _errors = {
            title: (post.title == undefined || post.title === ""),
            channel: (post.channel == undefined || post.channel === ""),
            content: (post.content == undefined || post.content === ""),
        }

        setErrors(_errors)

        // return true if post is valid
        return !(_errors.title || _errors.channel || _errors.content)
    }

    function createPost(event) {
        event.preventDefault()

        setLoading(true)

        const postID = v4().split("-").join("").substring(0, 12)

        let _post = {
            id: postID,
            title: post.title,
            channel: post.channel,
            content: post.content,
            tags: [],
            upvotes: 0,
            downvotes: 0,
            relatedURL: "",
            user: user.username
        }

        const isValidPost = validatePost(_post)

        if (isValidPost) {

            // extracting tags:
            const tagRegex = /#([^\s]+)/gm
            _post.tags = post.content.match(tagRegex)

            API.graphql({
                query: mutations.createPost,
                variables: {
                    input: _post
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            }).then((newPost) => {
                navigate("/")
                setNotification("Congrats!", "Posted Successfully", "success")
                setLoading(false)
            }).catch((error) => {
                console.error("error creating new post:", error)
                setNotification("Error occured", "", "error")
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }

    function applyStyling(style) {
        let content = document.getElementById("post-content")
        let start = content.selectionStart
        let end = content.selectionEnd

        if (start !== end) {
            let text = content.value

            if (style === "bold") {
                text = text.slice(0, start) + "**" + text.slice(start, end) + "**" + text.slice(end, -1)
            }

            if (style === "h1") {
                text = text.slice(0, start) + "# " + text.slice(start, end) + "\n" + text.slice(end, -1)
            }

            if (style === "h2") {
                text = text.slice(0, start) + "## " + text.slice(start, end) + "\n" + text.slice(end, -1)
            }

            if (style === "url") {
                text = text.slice(0, start) + "[Link](" + text.slice(start, end) + ")" + text.slice(end, -1)
            }

            setPost({ ...post, content: text })
        }
    }

    return (
        <div>

            <div className='flex flex-row justify-between'>
                <h2 className='text-2xl'>
                    New Post
                </h2>

                <div>
                    <ButtonGroup>
                        <Button isLoading={loading}>Preview</Button>
                        <Button variation='primary' isLoading={loading} loadingText="Posting..." onClick={createPost}>Post</Button>
                    </ButtonGroup>
                </div>
            </div>

            <form className="grid grid-cols-3 gap-4 py-4">
                <TextField
                    className='col-span-2'
                    id="post-title"
                    name="post-title"
                    placeholder="Title"
                    errorMessage={undefined}
                    hasError={errors.title}
                    isRequired={true}
                    onChange={(e) => setPost({ ...post, title: e.target.value })}
                />

                <SelectField value={post.channel} hasError={errors.channel} className='col-span-1' id="post-channel" name="post-channel" placeholder='Select channel...' isRequired={true} onChange={(e) => setPost({ ...post, channel: e.target.value })}>
                    {channels.map(c => (<option key={c.name} value={c.name} label={c.name}> {c.name} </option>))}
                </SelectField>

                <div id="post-content-wrapper" className='col-span-3'>
                    <ButtonGroup className='mb-4'>
                        <Button padding={0} variation='link' onClick={() => applyStyling("bold")}><TbBold className='h-5 w-5 m-2' /></Button>
                        <Button padding={0} variation='link' onClick={() => applyStyling("h1")}><TbH1 className='h-5 w-5 m-2' /></Button>
                        <Button padding={0} variation='link' onClick={() => applyStyling("h2")}><TbH2 className='h-5 w-5 m-2' /></Button>
                        <Button padding={0} variation='link' onClick={() => applyStyling("url")}><TbLink className='h-5 w-5 m-2' /></Button>
                    </ButtonGroup>

                    <textarea
                        id="post-content"
                        className='w-full'
                        name="post-content"
                        rows={25}
                        required={true}
                        placeholder="Share you thoughts..."
                        style={{ border: "none", outline: "none", resize: "none" }}
                        onChange={(e) => setPost({ ...post, content: e.target.value })}
                        value={post.content}
                    />
                </div>

            </form>
        </div>
    )
}

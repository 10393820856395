import React from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';

import PostsList from '../components/PostsList';
import NotificationContext from '../Notifications';
import { SubHeader } from '../components/Text';
import WritePostButton from '../components/WritePostButton';

function Main() {
    const { authStatus } = useAuthenticator((context) => [context.authStatus])
    const isAuthenticated = authStatus === "authenticated"
    const { setNotification } = React.useContext(NotificationContext)

    return (
        <div>

            <div id="latet-posts-header" className='flex flex-row justify-between'>
                <SubHeader text="Latest Posts"/>
                <WritePostButton />
            </div>

            <PostsList/>

        </div>
    );
}


export default Main;

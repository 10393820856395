// AuthContext.js
import { Alert } from '@aws-amplify/ui-react';
import { createContext, useState } from 'react';

// const Notification_TIME = 5000;

const initialState = {
    title: undefined,
    text: undefined,
    type: undefined,
};

const NotificationContext = createContext({
    ...initialState,
    setNotification: () => { },
});

export const NotificationProvider = ({ children }) => {
    const [title, setTitle] = useState(undefined);
    const [text, setText] = useState(undefined);
    const [type, setType] = useState(undefined);

    const setNotification = (title, text, type) => {
        setTitle(title)
        setText(text);

        if (type) {
            setType(type);
        } else {
            setType("info")
        }

        // TODO: implement timer destructor
        // setTimeout(() => {
        //     setText('');
        //     setType('');
        // }, Notification_TIME);
    };

    const dismiss = () => {
        setTitle(undefined)
        setText(undefined)
        setType(undefined)
    }

    return (
        <NotificationContext.Provider
            value={{
                title,
                text,
                type,
                setNotification,
            }}
        >
            {
                title !== undefined ? (
                    <Alert
                        style={{zIndex: 1000}}
                        className='fixed top-4 right-4'
                        variation={type}
                        heading={title}
                        isDismissible={true}
                        onDismiss={ dismiss }
                    >
                        {text}
                    </Alert>
                ) : ""
            }

            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
import React from 'react'

import { Badge, Button, useAuthenticator } from '@aws-amplify/ui-react'
import { Link, useParams } from 'react-router-dom'

import PostsList from '../components/PostsList'
import { SubHeader } from '../components/Text'
import NotificationContext from '../Notifications'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import WritePostButton from '../components/WritePostButton'

export default function Channel() {
    const {channel} = useParams()
    
    const { authStatus } = useAuthenticator((context) => [context.authStatus])
    const isAuthenticated = authStatus === "authenticated"
    const { setNotification } = React.useContext(NotificationContext)

    return (
        <div>

            <div id="latet-posts-header" className='flex flex-row justify-between'>
                <SubHeader text={channel}/> 
                <WritePostButton channel={channel} />
            </div>

            <PostsList channel={channel} limit={20}/>

        </div>
    )
}

import React from 'react'

export function Header({text}) {
    return (
        <h1 className='text-4xl font-bold'>{text}</h1>
    )
}

export function SubHeader({text}) {
    return (
        <h1 className='text-2xl font-bold'>{text}</h1>
    )
}
import { HiEye } from "react-icons/hi";

export const channels = [
    { name: "General", icon: <HiEye/> },
    { name: "Vision", icon: <HiEye/> },
    { name: "Language", icon: <HiEye/> },
    { name: "Reinforcement", icon: <HiEye/> },
    { name: "MLOps", icon: <HiEye/> },
    // { name: "Tools", icon: <HiEye/> },
]

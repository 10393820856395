import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// AMPLIFY
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import SignUpView from './views/SignUp';
import LoginView from './views/Login';
import { Authenticator } from '@aws-amplify/ui-react';
import { NotificationProvider } from './Notifications';

// SCREENS
import App from './App';
import Main from './views/Main';
import PostView from './views/PostView';
import PostCreate from './views/PostCreate';
import ListChannels from './views/ListChannels';
import Channel from './views/Channel';
import Profile from './views/Profile';
import JobBoard from './views/JobBoard';
import UserPosts from './views/UserPosts';
import PageNotFound from './views/PageNotFound'
import './index.css';

// default auth mode for graphql operations
// awsExports['aws_appsync_authenticationType'] = "AMAZON_COGNITO_USER_POOLS"

Amplify.configure(awsExports);

// ROUTING
let router = createBrowserRouter([
    {
        path: "/", element: <App />, children: [
            { path: "/", element: <Main /> },
            { path: "/signup", element: <SignUpView /> },
            { path: "/login", element: <LoginView /> },
            { path: "/post/new", element: <PostCreate /> },
            { path: "/post/:postID", element: <PostView /> },
            { path: "/channel", element: <ListChannels /> },
            { path: "/channel/:channel", element: <Channel /> },
            { path: "/user/:username", element: <UserPosts /> },
            { path: "/user/:username/posts", element: <UserPosts /> },
            { path: "/profile", element: <Profile /> },
            { path: "/jobs", element: <JobBoard /> },
            // { path: "/learning", element: <LoginView /> },
        ],
        errorElement: <PageNotFound/>
    },
])

// RENDER
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Authenticator.Provider>
        <React.StrictMode>
            <NotificationProvider>
                <RouterProvider router={router} />
            </NotificationProvider>
        </React.StrictMode>
    </Authenticator.Provider>
);

import React from 'react'

import { Tabs, TabItem, useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { HiBadgeCheck, HiUserCircle } from 'react-icons/hi'
import { SubHeader } from '../components/Text'
import { LoaderContext } from '../Context'

// AUTH BUILTINS: address, birthdate, email, family name, gender, given name, locale, middle name, name, nickname, phone number, picture, preferred username, profile, zoneinfo, updated at, website


function ProfileTab({ user }) {
    if (!user.attributes) {
        user.attributes = {}
        console.error("MISSING USER ATTRIBUTES")
    }
    return (
        <div className='flex flex-col gap-8'>
            <div className=''>
                <h3 className=''>Email</h3>
                <span className='font-bold'>
                    {user.attributes.email}
                    {user.attributes.email_verified ? <HiBadgeCheck className='h-4 w-4 mx-2 inline' style={{ color: "var(--amplify-colors-background-info)" }} /> : ""}
                </span>
            </div>
        </div>
    )
}


function SettingsTab({ user }) {
    if (!user.attributes) {
        user.attributes = {}
        console.error("MISSING USER ATTRIBUTES")
    }
    return (
        <div className='flex flex-col gap-8'>
            Settings...
        </div>
    )
}


export default function Profile() {
    const [user, setUser] = React.useState({})
    const { isLoading, setLoading } = React.useContext(LoaderContext)
    const [activeTab, setActiveTab] = React.useState(0)

    React.useEffect(() => {
        setLoading(true)
        Auth.currentAuthenticatedUser()
            .then((u) => setUser(u))
            .catch((e) => console.error(e))
            .finally(() => setLoading(false))
    }, [])

    if (Object.keys(user).length === 0) {
        return (<div></div>)
    }

    return (
        <div>
            <div className='flex flex-row items-center'>
                <HiUserCircle className='h-32 w-32 m-4' />
                <SubHeader text={`@${user.username}`} className="" />
            </div>

            <Tabs defaultIndex={0} currentIndex={activeTab} onChange={(i) => setActiveTab(i)} className="mb-8">
                <TabItem title="Profile">
                    <ProfileTab user={user} />
                </TabItem>
                <TabItem title="Settings">
                    <SettingsTab user={user}/>
                </TabItem>
            </Tabs>
        </div>
    )
}

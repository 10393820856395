/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://eer4lqyznzcntmjh5csebsdzey.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wwxe6jm2zvazdki44ryg27kqf4",
    "aws_cognito_identity_pool_id": "us-east-1:5792a54e-a8e1-4929-a9c5-82faf25105c4",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rPypGevZx",
    "aws_user_pools_web_client_id": "36440635illf1srp63alua61pu",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "thebackprop-20230127214347-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://thebackprop-20230127214347-hostingbucket-main.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;

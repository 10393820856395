import React from 'react'
import { useParams } from 'react-router-dom'

import { Amplify, API } from 'aws-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react'
import * as queries from '../graphql/queries'

import PostsList from '../components/PostsList'
import { SubHeader } from '../components/Text'

export default function UserPosts() {
    const { username } = useParams()
    const { authStatus } = useAuthenticator((context) => [context.authStatus])

    return (
        <div>

            <div id="latet-posts-header" className='flex flex-row justify-between'>
                <SubHeader text={`Posts by @${username}`} />
            </div>

            <PostsList user={username} limit={20} showPostedBy={false} />

        </div>
    )
}

import React from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';

import { API, Auth } from 'aws-amplify';
import { Button, Menu, MenuItem, useAuthenticator, useTheme } from '@aws-amplify/ui-react';
import * as queries from './graphql/queries'
import * as mutations from './graphql/mutations'

import { HiUser } from 'react-icons/hi'
import { LoaderContext } from './Context';


function App() {
    const { user, authStatus } = useAuthenticator((context) => [context.user])
    const [userSettings, setUserSettings] = React.useState({})
    const [isLoading, setLoading] = React.useState(false)
    const theme = useTheme()
    const location = useLocation()

    const activeLinkBgColor = theme.tokens.components.button.link._hover.backgroundColor.value
    const inactiveLinkBgColor = theme.tokens.components.button.link.backgroundColor.value

    const navigate = useNavigate()

    function logout() {
        if (confirm("Are you sure?")) {
            Auth.signOut().then(() => {
                navigate("/")
                window.location.reload(true)
            })
        }
    }

    React.useEffect(() => {
        if (authStatus === "authenticated" && user && user.username) {

            API.graphql({
                query: queries['getUserSettings'],
                variables: {
                    user: user.username
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })
                .then(({ data }) => {
                    const _userSettings = data['getUserSettings']
                    console.log("Got user settings:", _userSettings)

                    if (!_userSettings) {
                        console.log("creating user settings...")
                        API.graphql({
                            query: mutations.createUserSettings,
                            variables: {
                                input: {
                                    user: user.username,
                                    favoriteChannels: [],
                                    bookmarks: [],
                                    upvotes: [],
                                    downvotes: [],
                                }
                            },
                            authMode: "AMAZON_COGNITO_USER_POOLS"
                        }).then(({data}) => {
                            setUserSettings(data['getUserSettings'])
                        }).catch((e) => console.log(e))
                    } else {
                        setUserSettings(_userSettings)
                    }

                })
                .catch((error) => console.log(error))

        }




    }, [user])

    return (
        <LoaderContext.Provider value={{ isLoading, setLoading }}>
            <div>
                <div className='grid grid-flow-col grid-cols-3 w-full'>

                    <div id="links-section" style={{ margin: "auto 1em", verticalAlign: "middle" }}>
                        <Link to="/channel">
                            <Button variation='link' color="black" backgroundColor={location.pathname == "/channels" ? activeLinkBgColor : undefined}>
                                Channels
                            </Button>
                        </Link>
                        {/* <Link to="/learning">
                            <Button variation='link' color="black" backgroundColor={location.pathname == "/learning" ? activeLinkBgColor : undefined}>
                                Learn
                            </Button>
                        </Link> */}
                        <Link to="jobs">
                            <Button variation='link' color="black" backgroundColor={location.pathname == "/jobs" ? activeLinkBgColor : undefined}>
                                Jobs
                            </Button>
                        </Link>
                    </div>

                    <span className="text-3xl font-extrabold my-4 text-center">
                        <Link to="/">
                            thebackprop
                        </Link>
                    </span>

                    <div id="user-section" style={{ margin: "auto 1em", verticalAlign: "middle" }} className="text-right">
                        {
                            user ? (
                                <Menu menuAlign='end' trigger={<Button variation='link'><HiUser className='h-4 w-4 m-2' /> {user.username}</Button>}>
                                    <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
                                    <MenuItem onClick={() => navigate("/settings")}>Settings</MenuItem>
                                    <MenuItem onClick={logout}>Logout</MenuItem>
                                </Menu>
                            ) : (
                                <span>
                                    <Link to="/signup">
                                        <Button variation='link' backgroundColor={location.pathname == "/signup" ? activeLinkBgColor : undefined}>
                                            Sign Up
                                        </Button>
                                    </Link>
                                    <Link to="/login">
                                        <Button variation='link' backgroundColor={location.pathname == "/login" ? activeLinkBgColor : undefined}>
                                            Login
                                        </Button>
                                    </Link>
                                </span>
                            )
                        }
                    </div>
                </div>

                <div className={`${isLoading ? 'loader' : ''} h-1 w-10 before:bg-slate-400`} ></div>

                <hr className='mb-8' />

                <div className='grid grid-flow-col grid-cols-12'>
                    <div className='col-span-3'></div>
                    <div className='col-span-6'><Outlet /></div>
                    <div className='col-span-3'></div>
                </div>
            </div>
        </LoaderContext.Provider >
    );
}


export default App;

import React from 'react'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { useNavigate } from 'react-router-dom'

export default function LoginView() {
    const { route } = useAuthenticator((context) => [context.route]);
    const navigate = useNavigate();

    React.useEffect(() => {
      if (route === 'authenticated') {
        navigate("/", { replace: true });
      }
    }, [ route ]);

    return (
        <div className='mt-24'>
            <Authenticator initialState="signIn"/>
        </div>
    )
}

import React from 'react'
import { IconManifest, IconTree } from 'react-icons/'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import PostsList from '../components/PostsList'
import { Header, SubHeader } from '../components/Text'
import { channels } from '../config'

export default function Channels() {
    return (
        <div>
            <div className='text-center'>
                <Header text="Channels" />
            </div>

            {
                channels.map(({ name, icon }) => (
                    <div className='mb-8 mt-4' key={name}>
                        <Link to={`/channel/${name}`}>
                            <h2 className='text-2xl font-bold inline align-baseline'>
                                {name}
                                <HiOutlineArrowRight className='h-4 w-4 m-2 inline align-middle' />
                            </h2>
                        </Link>
                        <PostsList channel={name} limit={4} showChannelBadge={false} />
                    </div>
                ))
            }

        </div>
    )
}

import React from 'react'

import { Button, useAuthenticator } from '@aws-amplify/ui-react'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'

export default function WritePostButton({channel}) {
    const { authStatus } = useAuthenticator((context) => [context.authStatus])
    const isAuthenticated = authStatus === "authenticated"
    const navigate = useNavigate()

    function navigateToForm() {
        navigate({
            pathname: '/post/new',
            search: channel ? `?${createSearchParams({channel:channel})}` : "",
          });
    }

    if (isAuthenticated) {
        return (<Button onClick={navigateToForm} variation='link' color="black"><HiOutlinePencilAlt className='h-4 w-4 m-2'/>Write</Button>)
    }

    return (<span></span>)
}

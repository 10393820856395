import React from 'react'

import { Authenticator } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'

export default function SignUpView() {
    return (
        <div>
            <div>
                <Link to="/">thebackprop.net</Link>
                <hr/>
            </div>

            <Authenticator initialState="signUp"/>
        </div>
    )
}

import { Badge, SelectField } from '@aws-amplify/ui-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Header } from '../components/Text'


const JOBS = [
    {
        id: 1,
        position: "Data Engineer",
        description: `We are looking for an enthusiastic data engineer to join our Data and Analytics team.


        You will use various methods to transform raw data into useful data systems.
        
        Overall, you’ll strive for efficiency by aligning data systems with business goals.
        
        
        To succeed in this position, you should have strong analytical skills and the ability to combine data from different sources. problem-solving and excellent communication skills.
        
        If you are detail-oriented and experienced in this field, we’d like to hear from you.
        
        
        Responsibilities
        
        Analyze and organize raw data
        Build data systems and pipelines
        Evaluate business needs and objectives
        Conduct complex data analysis and report on results
        Prepare data for prescriptive and predictive modeling
        Combine raw information from different sources
        Explore ways to enhance data quality and reliability
        
        
        Requirements:
        
        Excellent knowledge and profound Hands-on experience with SQL
        Previous experience as a data engineer or in a similar role
        Technical expertise in data models, data mining, and segmentation techniques
        Great numerical and analytical skills
        Team player with a can-do approach.
        
        Advantages:
        
        Knowledge of programming languages (e.g. Java and Python)
        Front-end tool knowledge and experience (PBI, Tableau, Sisense)
        Familiar with AWS data platform
        Familiar with SAAS KPIs
        Familiar with Snowflakes, DBT, ETL tools
        
        Aqua is a unicorn in the exciting and fast-growing cloud-native security space, leading the digital transformation of the world’s largest organizations using containers, Kubernetes, and serverless.
        
        Every Aquarian makes an impact. Our highly collaborative culture sparks bonds amongst Aquarians empowering us to boldly drive change. We thrive by constantly being challenged to deliver the best, most innovative technology solutions to our customers.
        
        Join our team of cloud-native experts for a world of global opportunities and continuous career development!`,
        company: "Aqua Security",
        location: "Ramat Gan, Israel",
        job_type: "Full-time, Hybrid",
        skills: ["Python", "SQL", "AWS", "Data mining", "Data models", "Java"],
        _link: "https://www.linkedin.com/jobs/view/3476369979"
    },
    {
        id: 2,
        position: "Senior Data Engineer",
        description: `Revuze is the first company to build a data warehouse of ready market research answers, helping brands cope with the fast-changing landscape of their categories via multiple product lines that serve any role and need in any size brand. Since 2013, Revuze has been servicing some of the biggest brands in the world with access to valuable consumer insights.

    Scope of work:
    Design & develop our data pipelines infrastructure using our cloud infrastructure based on AWS
    Define the company’s data engineering roadmap and make sure it’s implemented using cutting-edge technologies
    Collaborate with various stakeholders across the company such as data analysts, engineers and product managers
    Required skills:
    3 + years experience working as a Data Engineer
    Proficiency with Python, PySpark, SQL
    Experience with Elastic, AWS
    Experience with ETL pipelines design, implementation and maintenance
    Experience working with relational and non-relational databases and data modeling
    B.Sc. in Computer Science, Engineering or equivalent field
    Fast and self-learner with ability to work independently
    Excellent communication skills in English
    `,
        company: "Revuze",
        location: "Netanya, Israel",
        job_type: "Full-time, On-site",
        skills: ["Python", "SQL", "AWS", "PySpark"],
        _link: "https://www.linkedin.com/jobs/view/3455354140"
    },
    {
        id: 3,
        position: "Machine Learning Software Engineer",
        description: `About Our Machine Learning Software Engineer

    Dynamic Yield is on the lookout for a Machine Learning Software Engineer with orientation to Machine Learning pipelines. As part of our team, this role is responsible for all engineering aspects of our machine learning algorithms and A/B test mechanisms at scale while working closely with the company’s data science team. The job requires someone skillful at the latest big data batch and streaming technologies. We are seeking outstanding software developers, with strong OOP capabilities, deep understanding of distributed systems, and passion for data science and algorithms.
    
    You’ll be expected to utilize advanced technical skills and critical thinking abilities to focus on appropriate methods for evaluation for optimal results by using a range of technological stacks we use: Spark, Flink, HBase, Kafka, Redis, Elasticsearch and we code mainly in Java, Scala and Python. A strong team player with great communication skills while having the know-how to deliver high-levels of coding, design, testing, and execution.
    
    The Task-at-Hand:
    Design, develop and maintain Big Data workflows/pipelines
    Create a machine learning infrastructure from training to predict
    Develop A/B testing features and tools
    Build, maintain and execute unit test cases with high code coverage
    Work in teams and collaborate with others
    Bring a strong opinion to the table and be proactively involved with product planning
    Be fully responsible for the product’s lifecycle - from design and development to deployment
    
    Optimal Skills for Success:
    
    2-3 years of experience as a software engineer
    2-3 years of hands-on experience in engineering Scala/Java/Python
    Deep understanding of Machine Learning algorithms and models - a plus
    Experience with Big Data / NoSQL / Stream processing technologies (e.g Spark,Flink, Redis, Kafka) - a plus
    Proven experience with implementing production models (Infrastructure, data pipelines etc) - a plus
    Bachelor’s degree in Computer Science or related
    Experience working on cloud solutions (AWS a big advantage) - a plus`,
        company: "Dynamic Yield",
        location: "Tel Aviv, Israel",
        job_type: "Full-time, On-site",
        skills: ["Python", "Apache Kafka", "Apache Spark", "HBase", "Java", "Redis", "Scala"],
        _link: "https://www.linkedin.com/jobs/view/3429048560"
    },
    {
        id: 4,
        position: "Algorithm Developer- Deep Learning / Machine Learning",
        description: `The semiconductor industry is driving the development of memory devices and computational power. We are setting the foundations for the IoT and AI future. Applied materials is a 100 billion company that open the door to the future.

    In the algorithm’s group we are developing sate of the art computer vision algorithms that are required to measure devices in a sub-nanometer accuracy and very high reliability.
    
    Another important task of the algorithm group is detection and classification nanometer scale defects in the manufacturing process which is a highly challenging algorithmic task.
    
    The Deep learning waves already started to change our industry, but it is a revolution in its early stages.
    
    we are looking for a Deep Learning expert that will join us in leading the DL revolution in the Semiconductor industry.
    
    Roles & Responsibilities
    
    Develop and research state-of-the-art algorithms in the fields of Computer vision using Deep Learning technology.
    Drive state-of-the-art projects, teach, mentor, or advise the internal teams.
    Collaborate with cross-functional teams: physics, software, applications and build new products that impact the lives of billions.
    
    Requirements
    
    M.Sc. graduate (or above) in Electrical Engineering / Physics / Computer Science / Mathematics.
    Have theoretical and hands-on experience (4+ years) in Deep learning.
    Experience in bringing DL project from research to product
    Able to work independently as well as part of a team.
    
    #LI
    
    Qualifications
    
    Education:
    
    Bachelor's Degree
    
    Skills
    
    Certifications:
    
    Languages:
    
    Years of Experience:
    
    4 - 7 Years
    
    Work Experience:
    
    Additional Information
    
    Travel:
    
    Yes, 20% of the Time
    
    Relocation Eligible:
    
    No
    
    Applied Materials is an Equal Opportunity Employer committed to diversity in the workplace. All qualified applicants will receive consideration for employment without regard to race, color, national origin, citizenship, ancestry, religion, creed, sex, sexual orientation, gender identity, age, disability, veteran or military status, or any other basis prohibited by law.`,
        company: "Applied Materials",
        location: "Rehovot, Israel",
        job_type: "Full-time, On-site",
        skills: ["Algorithms", "Machine learning", "Deep learning", "Image processing"],
        _link: "https://www.linkedin.com/jobs/view/3476173300"
    },
    {
        id: 5,
        position: "Data Scientist",
        description: `We’re an experienced and diverse team focused on solving one of the biggest challenges CIOs and CISOs have - allowing development teams to move fast without compromising on security controls and risk management. we’re looking for strong and fun people to join us and take a significant part in shaping our technology, product, and culture.

    What You’re About
    
    You have at least five years of experience as a data scientist and proficiency in machine learning. You have a solid software engineering background and hands-on experience (writing code for production).
    
    You have a Ph.D./M.Sc. in math, physics, engineering, computer science, or an equivalent science degree.
    
    Preferably, you have experience in NLP, or with the cybersecurity industry, startups environment and you have led a team of data scientists, and built data infrastructure.
    
    You are experienced with solving complex data science problems from ideation to production and writing code in Python using Python data-science frameworks. You are well experienced with multiple programming languages as C#, java, node.js, as well as with cloud tools and cloud ML platforms.
    
    What You Will Do
    
    You will be a part of a strong ML/AI team, work on end-to-end ML projects from data extraction through model validation to model deployment.
    
    You will engage in translating business and product requirements, goals, and challenges as well as customers’ feedback into data-pipelines and model framework.
    
    Research, design, develop, test, and deploy models and systems by applying data-driven Machine Learning and AI solutions, from conceptual analysis to production - are all part of your role.
    
    You will extract various types of data from external data sources to identify interesting and relevant features patterns. You will be required to utilize data mining techniques, perform statistical analysis, and build prediction models accordingly.
    
    You will be presenting insights to customers, business, and engineering teams, while working closely with apiiro's team on data cycle-related processes - extraction, labeling, verification, and integrations.
    `,
        company: "Apiiro",
        location: "Tel Aviv, Israel",
        job_type: "Full-time, Hybrid",
        skills: ["Machine learning", "Data mining", "Log4j", "Pattern recognition", "Software development"],
        _link: "https://www.linkedin.com/jobs/view/3473659982"
    },
]

export default function JobBoard() {
    const [locationFilter, setLocationFilter] = React.useState("")
    const [jobs, setJobs] = React.useState(JOBS)

    // extract all location
    let allLocations = JOBS.map(job => job.location)

    // unique list of location
    allLocations = allLocations.filter((loc, index) => allLocations.indexOf(loc) == index)

    return (
        <div>
            <div className='flex flex-row justify-between'>
                <Header text="ML Jobs" />
                <SelectField className='w-fit float' value={locationFilter} onChange={(e) => setLocationFilter(e.target.value)}>
                    <option value="">All locations</option>
                    {
                        allLocations.map(location => (<option key={location} value={location}>{location}</option>))
                    }
                </SelectField>
            </div>

            <div className='flex flex-col gap-4 my-8'>
                {
                    jobs.map(job => {
                        if ((locationFilter !== "" && job.location === locationFilter) || (locationFilter === "")) {
                            return (
                                <div key={job.id} className='border border-black px-4 py-2'>
                                    <a href={job._link} target="_blank"><strong>{job.position}</strong></a>
                                    <br /> @ {job.company} [{job.location}]
                                    <br /> {job.skills.map(skill => (<Badge key={skill} size="small" className='mr-2 mt-2'>{skill}</Badge>))}
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}
